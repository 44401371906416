import React, { useCallback } from 'react'
import { useActions } from '../../../../../hooks'
import { categories as categoriesActions } from '../../../../../store/actions'
import MainCategories from './MainCategories'

const MainCategoriesContainer = () => {
  const [categoriesDispatch] = useActions([categoriesActions])
  const getCategoriesList = useCallback(categoriesDispatch.getCategoriesList, [categoriesDispatch])

  const props = {
    getCategoriesList,
  }

  return <MainCategories {...props} />
}

export default MainCategoriesContainer
