import React, { useCallback } from 'react'
import { useActions } from '../../../../../hooks'
import { webinars as webinarsActions } from '../../../../../store/actions'
import OtherPreviousWebinars from './OtherPreviousWebinars'

const OtherPreviousWebinarsContainer = ({ ...rest }) => {
  const [webinarsDispatch] = useActions([webinarsActions])
  const getWebinarsRecorded = useCallback(webinarsDispatch.getWebinarsRecorded, [webinarsDispatch])

  const props = {
    getWebinarsRecorded,
    ...rest,
  }

  return <OtherPreviousWebinars {...props} />
}

export default OtherPreviousWebinarsContainer
