import React from 'react'
import { Col, Grid, Image, Row } from 'antd'
import { Link } from 'react-router-dom'
import { useShallowEqualSelector } from '../../../../../hooks'
import AwardImage from './images/badge-award.png'
import AwardLogo from './images/logo-awards.png'
import LawLogo from './images/logo-lawskills.png'
import WomanImage from './images/woman.png'
import { StyledBox, StyledContainer, StyledContent, StyledCopy, StyledLogo } from './styled'

const { useBreakpoint } = Grid

const MainHighlights = () => {
  const screens = useBreakpoint()
  const { isLogged } = useShallowEqualSelector((state) => state.auth)

  return (
    <StyledContainer>
      <StyledContent>
        <Row gutter={32}>
          <Col md={24} xl={12}>
            <StyledBox>
              <Row wrap={false} gutter={40}>
                <Col>
                  <StyledLogo src={LawLogo} preview={false} />
                  <StyledCopy>
                    Estate Research Learning Hub has acquired content from Gill Steel’s Lawskills -{' '}
                    <Link to={isLogged ? '/gill-steel/webinars' : '/login'}>click here to access</Link>
                  </StyledCopy>
                </Col>
                {screens.xl && (
                  <Col>
                    <Image
                      src={WomanImage}
                      preview={false}
                      style={{ marginBottom: '-2rem', height: 200, width: 'auto' }}
                    />
                  </Col>
                )}
              </Row>
            </StyledBox>
          </Col>
          <Col md={24} xl={12}>
            <StyledBox>
              <Row wrap={!screens.lg} gutter={40}>
                <Col xs={24} flex='auto'>
                  <StyledLogo src={AwardLogo} preview={false} />
                  <StyledCopy>
                    Estate Research Learning Hub wins the Best use of Technology award at the The British Wills &
                    Probate Awards 2023
                  </StyledCopy>
                </Col>
                <Col xs={24} flex='auto' style={{ textAlign: 'center' }}>
                  <Image src={AwardImage} preview={false} style={{ marginTop: screens.xl ? 0 : 24 }} />
                </Col>
              </Row>
            </StyledBox>
          </Col>
        </Row>
      </StyledContent>
    </StyledContainer>
  )
}

export default MainHighlights
