import React, { useCallback } from 'react'
import { useActions } from '../../../hooks'
import { auth as authActions } from '../../../store/actions'
import ChangePasswordPage from './ChangePasswordPage'

const ChangePasswordPageContainer = () => {
  const [authDispatch] = useActions([authActions])
  const authChangePassword = useCallback(authDispatch.authChangePassword, [authDispatch])
  const resetAuth = useCallback(authDispatch.resetAuth, [authDispatch])

  const props = {
    authChangePassword,
    resetAuth,
  }

  return <ChangePasswordPage {...props} />
}

export default ChangePasswordPageContainer
