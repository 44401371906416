/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { Typography, Col, Row } from 'antd'
import { useDocumentTitle } from '../../../hooks'
import { theme } from '../../../styles'
import { Ellipse } from '../../components'
import { PrivacyPolicy } from './styled'

const { Title, Paragraph, Text } = Typography

const PrivacyPolicyPage = () => {
  useDocumentTitle('Privacy Policy')

  return (
    <PrivacyPolicy>
      <PrivacyPolicy.SemiCircleBlue>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={8} rotate={-45} />
      </PrivacyPolicy.SemiCircleBlue>

      <PrivacyPolicy.SemiRingYellow>
        <Ellipse type='semiring' color={theme.colors.yellow[200]} size={25} rotate={-45} />
      </PrivacyPolicy.SemiRingYellow>

      <PrivacyPolicy.SemiRingRed>
        <Ellipse type='semiring' color={theme.colors.red[200]} size={16.2} rotate={-45} />
      </PrivacyPolicy.SemiRingRed>

      <PrivacyPolicy.SemiRingViolet>
        <Ellipse type='semiringCircle' color={theme.colors.violet[200]} size={8} rotate={-160} />
      </PrivacyPolicy.SemiRingViolet>

      <PrivacyPolicy.CircleYellow>
        <Ellipse type='circle' color={theme.colors.yellow[200]} size={15.3} />
      </PrivacyPolicy.CircleYellow>

      <PrivacyPolicy.PacmanBlue>
        <Ellipse type='pacman' color={theme.colors.lightBlue[200]} size={9.8} />
      </PrivacyPolicy.PacmanBlue>

      <PrivacyPolicy.Inner>
        <Title level={2}>Privacy Policy</Title>

        <Typography>
          <Paragraph>Last updated 26/04/22</Paragraph>

          <Paragraph>
            Thank you for choosing Estate Research (“the company”, “we”, “us”, or “our”). is the trading name of Estate
            Research Ltd., a company registered in England and Wales with company number 08765186 and Estate Research PC
            Ltd a company registered in England and Wales, 10253079; registered address: 102 Chapel Lane, Wigan, WN3
            4HG. We are committed to protecting your personal information and your right to privacy. If you have any
            questions or concerns about our policy, or our practices with regards to your personal information, please
            contact us with the details provided in the ‘How to Contact Us’ section.
          </Paragraph>

          <Paragraph>
            When you visit our Learning Hub (“Website”) (a division of Estate Research as described above) and use our
            learning support and services, you trust us with your personal information. We take your privacy very
            seriously. In this privacy notice, we describe our Learning Hub privacy policy. We seek to explain to you in
            the clearest way possible what information we collect, how we use it and what rights you have in relation to
            it. We hope you take some time to read through it carefully, as it is important. If there are any terms in
            this privacy policy that you do not agree with, please discontinue use of our website and our services.
          </Paragraph>

          <Paragraph>
            This privacy policy applies to all information collected through our Learning Hub Website and/or any related
            services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Sites").
          </Paragraph>

          <Paragraph>
            Please read this privacy policy carefully as it will help you make informed decisions about sharing your
            personal information with us.
          </Paragraph>
        </Typography>

        <PrivacyPolicy.Subtitle>How to contact us</PrivacyPolicy.Subtitle>

        <Paragraph>
          Email: <a href='mailto:info@estateresearchlearninghub.co.uk'>info@estateresearchlearninghub.co.uk</a>
          <br />
          Phone: <a href='tel:01942826500'>01942 826500</a>
          <br />
          Website: <a href='//estateresearchlearninghub.co.uk'>www.estateresearchlearninghub.co.uk</a>
          <br />
          Post: Addressed to Estate Research to 102 Chapel Lane, Wigan, WN3 4HG
        </Paragraph>

        <PrivacyPolicy.Subtitle level={5}>Data Protection Officer</PrivacyPolicy.Subtitle>

        <Paragraph>
          Estate Research has engaged with GRCI Law Ltd to act as the company’s Data Protection Officer. Accordingly,
          the DPO can be contacted as follows: Email: <a href='mailto:dpoaas@grcilaw.com'>dpoaas@grcilaw.com</a>{' '}
          (including Estate Research in the subject line) Post: Estate Research DPO, GRCI Law Ltd, Unit 3 Clive Court,
          Bartholomews Walk, Cambridgeshire Business Park, Ely, Cambridgeshire CB7 4EA
        </Paragraph>

        <PrivacyPolicy.Subtitle level={5}>Who this Privacy Policy applies to</PrivacyPolicy.Subtitle>

        <Paragraph>
          Estate Research is a genealogy service provider. This policy relates to users of Estate Research’s Learning
          Hub, an online platform dedicated to support the learning of legal professionals and other specialists by
          hosting webinars and sharing literature and other educational materials. Processing of your data is required
          in order to offer you these services. This policy applies to individuals who have shared their data with
          Estate Research as either a customer, employee, supplier or in any other capacity. It applies to all data that
          the company holds relating to identifiable individuals, even if that information technically falls outside of
          the UK GDPR. This can include: Names of Individuals, Company details, Postal addresses, Email Addresses,
          Telephone numbers and any business data necessary for us to provide you with the services you requested.
        </Paragraph>

        <PrivacyPolicy.Subtitle>Table of Contents</PrivacyPolicy.Subtitle>

        <Paragraph>
          <ol>
            <li>Definitions</li>
            <li>What iniformation do we collect?</li>
            <li>How do we use the data we collect?</li>
            <li>Will your information be share with anyone?</li>
            <li>Do we use cookies and other tracking technologies?</li>
            <li>Is your information transferred internationally?</li>
            <li>What is our stance on third-party websites?</li>
            <li>How long do we keep your information?</li>
            <li>How do we keep your information safe?</li>
            <li>Do we collect information from minors?</li>
            <li>What are your privacy rights?</li>
            <li>What control do I have over my privacy?</li>
            <li>Can I withhold information?</li>
            <li>Do we make updates to this policy?</li>
            <li>How can you contact us about this policy?</li>
          </ol>
        </Paragraph>

        <Title level={5}>1. Definitions</Title>

        <Paragraph>
          <Row>
            <Col span={8}>
              <Text strong>“Data”</Text>
            </Col>
            <Col span={16}>
              means collectively all information that you submit to ‘Estate Research’ via the Website. This definition
              shall, where applicable, incorporate the definitions provided in the Data Protection Act 2018 and UK GDPR;
            </Col>
          </Row>
        </Paragraph>

        <Paragraph>
          <Row>
            <Col span={8}>
              <Text strong>“Estate Research”</Text>
            </Col>
            <Col span={16}>means the trading name of ‘Estate Research Ltd and Estate Research PC Ltd’;</Col>
          </Row>
        </Paragraph>

        <Paragraph>
          <Row>
            <Col span={8}>
              <Text strong>“User”</Text>
            </Col>
            <Col span={16}>
              means any third party that accesses the Website and is not employed by ‘Estate Research’ and acting in the
              course of their employment; and
            </Col>
          </Row>
        </Paragraph>

        <Paragraph>
          <Row>
            <Col span={8}>
              <Text strong>“Website”</Text>
            </Col>
            <Col span={16}>
              means the website that you are currently using www.estateresearchlearninghub.co.uk and hosts the learning
              activities for professionals, also known as the “Learning Hub” and any sub-domains of this site unless
              expressly excluded by their own terms and conditions.
            </Col>
          </Row>
        </Paragraph>

        <Title level={5}>2. What information do we collect?</Title>

        <PrivacyPolicy.Subtitle>2.1 Personal Information you disclose to us</PrivacyPolicy.Subtitle>

        <Paragraph>
          We collect personal information that you voluntarily provide to us when registering with us andexpress an
          interest in obtaining information about us or our products and services, when participating in activities on
          the website (such as posting messages in our online forums or entering competitions, contests or giveaways) or
          otherwise contacting us.
        </Paragraph>

        <Paragraph>
          The personal information that we collect depends on the context of your interactions with us and our Sites,
          the choices you make and the products and features you use. The personal information we collect can include
          the following:
        </Paragraph>

        <ul>
          <li>
            Name and Contact Data. We collect your first and last name, email address, postal address, phone number, and
            other similar contact data.
          </li>
          <li>
            Credentials. We collect passwords, password hints, and similar security information used for authentication
            and account access.
          </li>
          <li>
            Payment Data. We collect data necessary to process your payment if you make purchases, such as your payment
            instrument number (such as a credit card number), and the security code associated with your payment
            instrument. All payment data is stored by our payment processor and you should review its privacy policies
            and contact the payment processor directly to respond to your questions.
          </li>
          <li>
            All personal information that you provide to us must be true, complete and accurate, and you must notify us
            of any changes to such personal information.
          </li>
        </ul>

        <PrivacyPolicy.Subtitle>2.2 Information automatically collected</PrivacyPolicy.Subtitle>

        <Paragraph>
          We automatically collect certain information when you visit, use or navigate our website. This information
          does not reveal your specific identity (like your name or contact information) but may include device and
          usage information, such as your IP address, browser and device characteristics, operating system, language
          preferences, referring URLs, device name, country, location, information about how and when you use our and
          other technical information. This information is primarily needed to maintain the security and operation of
          our website, and for our internal analytics and reporting purposes.
        </Paragraph>
        <Paragraph>We also collect information through cookies and similar technologies.</Paragraph>

        <PrivacyPolicy.Subtitle>2.3 Information Collected from Other Sources</PrivacyPolicy.Subtitle>

        <Paragraph>
          We may obtain information about you from other sources, such as public databases, joint marketing partners, as
          well as from other third parties. Examples of the information we receive from other sources include: marketing
          leads, search results and links.
        </Paragraph>

        <Title level={5}>3. How do we use the data we collect?</Title>

        <Paragraph>
          Any or all of the above Data may be required by us from time to time in order to provide you with the best
          possible service and experience when using our Website. Specifically, Data may be used by us for the following
          reasons:
        </Paragraph>

        <ul>
          <li>To register you as a new Learning Hub customer;</li>
          <li>
            To process and deliver the services you request including managing payments, fees and charges, and to
            collect and recover money owed to us;
          </li>
          Internal record keeping;
          <li>
            To use data analytics to improve our website, products/services, marketing, customer relationships and
            experiences;
          </li>
          <li>Transmission by email of promotional materials and events that may be of interest to you;</li>
          <li>To send information by email on behalf of our webinar sponsors from time to time;</li>
          <li>
            Contact for market research purposes which may be done using email, telephone, fax or mail. Such information
            may be used to customise or update the Website.
          </li>
        </ul>

        <Title level={5}>4. Will your information be shared with anyone?</Title>

        <Paragraph>We may process or share data based on the following legal basis:</Paragraph>

        <ul>
          <li>
            Consent: We may process your data if you have given us specific consent to use your personal information for
            a specific purpose.
          </li>
          <li>
            Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate
            business interests.
          </li>
          <li>
            Performance of a Contract: Where we have entered into a contract with you, we may process your personal
            information to fulfill the terms of our contract.
          </li>
          <li>
            Legal Obligations: We may disclose your information where we are legally required to do so in order to
            comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process,
            such as in response to a court order or a subpoena (including in response to public authorities to meet
            national security or law enforcement requirements).
          </li>
          <li>
            Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent,
            or take action regarding potential violations of our policies, suspected fraud, situations involving
            potential threats to the safety of any person and illegal activities, or as evidence in litigation in which
            we are involved.
          </li>
        </ul>

        <Paragraph>
          More specifically, we may need to process your data or share your personal information in the following
          situations:
        </Paragraph>

        <ul>
          <li>
            Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third party
            vendors, service providers, contractors or agents who perform services for us or on our behalf and require
            access to such information to do that work. Examples include: payment processing, data analysis, email
            delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to
            use tracking technology on the Website, which will enable them to collect data about how you interact with
            the Website over time. This information may be used to, among other things, analyse and track data,
            determine the popularity of certain content and better understand online activity. Unless described in this
            Policy, we do not share, sell, rent or trade any of your information with third parties for their
            promotional purposes.
          </li>
          <li>
            Business Partners. We may share your information with our business partners to offer you certain products,
            services or promotions. We may also send you a hosted
          </li>
          <li>
            Non-marketing Third Parties. Under the GDPR, we are also permitted to share some information with third
            parties who use such data for non-marketing purposes (including credit and risk assessment and management,
            identification and fraud prevention, debt collection and returning assets to you).
          </li>
        </ul>

        <Title level={5}>5. Do we use cookies and other tracking technologies?</Title>

        <Paragraph>
          We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
          information. Specific information about how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Policy below.
        </Paragraph>

        <Title level={5}>6. Is your information transferred internationally?</Title>

        <Paragraph>
          Our servers are located at our Head office in Wigan, UK. If you are accessing our services from outside,
          please be aware that your information may be transferred to, stored, and processed by us in our facilities and
          by those third parties with whom we may share your personal information (see "WILL YOUR INFORMATION BE SHARED
          WITH ANYONE?" above).
        </Paragraph>

        <Paragraph>
          If you are not a resident in the European Economic Area, then your country may not have data protection or
          other laws as comprehensive as those in your country. We will however take all necessary measures to protect
          your personal information in accordance with this Privacy Policy, our{' '}
          <a href='https://www.estateresearch.co.uk/privacy-policy/' target='_blank' rel='noreferrer'>
            general Privacy Policy
          </a>{' '}
          and applicable law.
        </Paragraph>

        <Title level={5}>7. What is our stance on third-party websites?</Title>

        <Paragraph>
          Our Website may contain advertisements from third parties that are not affiliated with us and which may link
          to other websites, online services or mobile applications. We cannot guarantee the safety and privacy of data
          you provide to any third parties. Any data collected by third parties is not covered by this privacy policy.
          We are not responsible for the content or privacy and security practices and policies of any third parties,
          including other websites, services or applications that may be linked to or from the Website. You should
          review the policies of such third parties and contact them directly to respond to your questions.{' '}
        </Paragraph>

        <Title level={5}>8. How long do we keep your information?</Title>

        <Paragraph>
          We will only keep your personal information for as long as it is necessary for the purposes set out in this
          privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or
          other legal requirements). When we have no ongoing legitimate business need to process your personal
          information, we will either delete or anonymize it, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will securely store your personal
          information and isolate it from any further processing until deletion is possible.
        </Paragraph>

        <Title level={5}>9. How do we keep your information safe?</Title>

        <Paragraph>
          We have implemented appropriate technical and organisational security measures designed to protect the
          security of any personal information we process. However, please also remember that we cannot guarantee that
          the Internet itself is 100% secure. Although we will do our best to protect your personal information,
          transmission of personal information to and from our Website is at your own risk. You should only access the
          services within a secure environment.
        </Paragraph>

        <Title level={5}>10. Do we collect information from minors?</Title>

        <Paragraph>
          We do not knowingly solicit data from or market to children under 18 years of age. By using the Website, you
          represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such
          minor dependent’s use of the Website. If we learn that personal information from users less than 18 years of
          age has been collected, we will deactivate the account and take reasonable measures to promptly delete such
          data from our records. If you become aware of any data we have collected from children under age 18, please
          contact us using the details provided above.
        </Paragraph>

        <Title level={5}>11. What are your privacy rights?</Title>

        <Paragraph>
          We are a data controller. In relation to the information that you provide to us, we are legally responsible
          for how that information is handled. We will comply with the GDPR (2018) in the way we use and share your
          personal data. Among other things, this means that we will only use your personal data:
        </Paragraph>

        <ul>
          <li>Fairly and lawfully;</li>
          <li>As set out in the legislation and this Policy;</li>
          <li>To the extent necessary for these purposes;</li>
          <li>We will take steps to ensure your data is accurate and rectify data as necessary.</li>
        </ul>

        <Paragraph>
          Under certain circumstances, you have rights under data protection laws in relation to your personal data.
          These include the right to:
        </Paragraph>

        <ul>
          <li>Request access to your personal data.</li>
          <li>Request correction of your personal data.</li>
          <li>Request erasure of your personal data.</li>
          <li>Object to processing of your personal data.</li>
          <li>Request restriction of processing your personal data.</li>
          <li>Request transfer of your personal data.</li>
          <li>Right to withdraw consent.</li>
        </ul>

        <Paragraph>
          You can see more about these rights at the{' '}
          <a href='https://www.ico.org.uk/' target='_blank' rel='noreferrer'>
            Information Commissioner's website.
          </a>
        </Paragraph>

        <Paragraph>
          You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However,
          we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively,
          we may refuse to comply with your request in these circumstances.
        </Paragraph>

        <Paragraph>
          We may need to request specific information from you to help us confirm your identity and ensure your right to
          access your personal data (or to exercise any of your other rights). This is a security measure to ensure that
          personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask
          you for further information in relation to your request to speed up our response.
        </Paragraph>

        <Paragraph>
          We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month
          if your request is particularly complex or you have made a number of requests. In this case, we will notify
          you and keep you updated.
        </Paragraph>

        <Title level={5}>12. What control do I have over my privacy?</Title>

        <Paragraph>
          If you would at any time like to review or change the information in your account or terminate your account,
          you can:
        </Paragraph>

        <Paragraph>
          Upon your request to terminate your account, we will deactivate or delete your account and information from
          our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot
          problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.
        </Paragraph>

        <Paragraph>
          <Text strong>Opting out of email marketing:</Text> You can unsubscribe from our marketing email list at any
          time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details
          provided below. You will then be removed from the marketing email list – however, we will still need to send
          you service-related emails that are necessary for the administration and use of your account.
        </Paragraph>

        <Title level={5}>13. Can I withhold information?</Title>

        <Paragraph>
          You may access the Website without providing any Data at all. However, to use all features and functions
          available on the Website you may be required to submit certain Data.
        </Paragraph>

        <Title level={5}>14. How to file a complaint</Title>

        <Paragraph>
          If you have a complaint, please email{' '}
          <a href='mailto:info@estateresearchlearninghub.co.uk'>info@estateresearchlearninghub.co.uk</a> and title your
          email ‘Complaint’, including your name, contact details and case reference. To ensure we are sending your
          personal data to you, we may need to contact you to verify your identity. Once we have verified your identity,
          we will respond to your complaint within 28 days. In the unlikely event that you were not satisfied by a
          complaint outcome handled by ourselves, you have the right to complain to the{' '}
          <a href='https://www.ico.org.uk/' target='_blank' rel='noreferrer'>
            Information Commissioner’s Office (ICO)
          </a>
          . Estate Research is registered with ICO as Estate Research Ltd - Registration number: Z2888257, or Estate
          Research PC Ltd - Registration number: ZA441379.
        </Paragraph>

        <Title level={5}>15. Do we make updates to this policy?</Title>

        <Paragraph>
          We may update this privacy policy from time to time. The updated version will be indicated by an updated
          “Revised” date and the updated version will be effective as soon as it is accessible.
        </Paragraph>

        <Paragraph>
          If we make material changes to this privacy policy, we may notify you either by prominently posting a notice
          of such changes or by directly sending you a notification. We encourage you to review this privacy policy
          frequently to be informed of how we are protecting your information.
        </Paragraph>

        <Title level={5}>16. Cookie Policy</Title>

        <Paragraph>
          For a full list of the cookies we collect and what information they store please refer to our Cookie Policy.
          You can find a link to our policy on the homepage of{' '}
          <a href='//estateresearch.co.uk' target='_blank' rel='noreferrer'>
            www.estateresearch.co.uk
          </a>
        </Paragraph>

        <PrivacyPolicy.Subtitle>Advertising cookies</PrivacyPolicy.Subtitle>

        <Paragraph>
          Remarketing is a way for us to connect with people who have recently interacted with our site. We may place
          remarketing cookies on your device during your visit so that our carefully selected third-party suppliers,
          including Google, may use the information gathered to show you our advertising when you visit other websites.
        </Paragraph>

        <PrivacyPolicy.Subtitle>Third party cookies</PrivacyPolicy.Subtitle>

        <Paragraph>
          We use some third party services or software on our website. If you go to a page on our website that contains
          this embedded content you may be sent cookies from these websites, for example{' '}
          <a href='https://vimeo.com/cookie_policy' target='_blank' rel='noreferrer'>
            Vimeo
          </a>{' '}
          videos or{' '}
          <a href='https://www.goto.com/company/legal/privacy/international' target='_blank' rel='noreferrer'>
            GotoWebinar
          </a>{' '}
          files. We do not control the setting of these cookies, so we suggest you check the third party website for
          more information about their cookies and how to manage them.
        </Paragraph>

        <PrivacyPolicy.Subtitle>Controlling use of cookies</PrivacyPolicy.Subtitle>

        <Paragraph>
          Most internet browsers automatically accept cookies unless you change your browser settings. If you wish to
          restrict, block or delete the cookies which are set by any websites, you can generally do this through your
          browser settings. These settings are usually found in the ‘options’ or ‘preferences’ menu of your internet
          browser.
        </Paragraph>

        <Paragraph>
          Please note, however, that if you set your internet browser preferences to block all cookies, you may not be
          able to access all or parts of our site.
        </Paragraph>

        <Paragraph>
          Unless you have adjusted your internet browser settings to block cookies, our site will set cookies.To find
          out more about cookies, including how to see what cookies have been set and how to manage and delete them,
          visit{' '}
          <a href='https://allaboutcookies.org/' target='_blank' rel='noreferrer'>
            AllAboutCookies
          </a>
          .
        </Paragraph>
      </PrivacyPolicy.Inner>
    </PrivacyPolicy>
  )
}

export default PrivacyPolicyPage
