import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Collapse, Grid, Modal, Typography } from 'antd'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import { images } from '../../../assets'
import { useShallowEqualSelector } from '../../../hooks'
import { Filter } from './styled'

const { useBreakpoint } = Grid
const { Title } = Typography
const { Panel } = Collapse

const parseSearchParams = () => queryString.parse(window.location.search, { arrayFormat: 'bracket' })

const FilterBy = ({
  getRegionsList,
  getCategoriesList,
  getSpeakersListFull,
  getBlogPostsCategories,
  getEventsLocations,
  visibleRegions,
  visibleCategories,
  visibleSpeakers,
  visibleBlogPostCategories,
  visibleEventsLocations,
  onRequest,
  context,
  customParams,
}) => {
  const navigate = useNavigate()
  const screens = useBreakpoint()

  const [filterVisible, setFilterVisible] = useState(false)
  const [showAllSpeakers, setShowAllSpeakers] = useState(false)

  const [regionsIds, setRegionsIds] = useState([])
  const [categoriesIds, setCategoriesIds] = useState([])
  const [speakersIds, setSpeakersIds] = useState([])
  const [blogPostCategoriesIds, setBlogPostCategoriesIds] = useState([])
  const [eventsLocationsIds, setEventsLocationsIds] = useState([])

  const { list: regionsList } = useShallowEqualSelector((state) => state.regions)
  const { list: categoriesList } = useShallowEqualSelector((state) => state.categories)
  const { speakersFull } = useShallowEqualSelector((state) => state.speakers)
  const { categories: blogPostCategories } = useShallowEqualSelector((state) => state.blogPosts)
  const { eventsLocations } = useShallowEqualSelector((state) => state.events)

  useEffect(() => {
    if (visibleRegions) {
      getRegionsList()
    }

    if (visibleCategories) {
      getCategoriesList({ display_all: 1, per_page: 'nolimit', ...customParams })
    }

    if (visibleSpeakers) {
      getSpeakersListFull()
    }

    if (visibleBlogPostCategories) {
      getBlogPostsCategories()
    }

    if (visibleEventsLocations) {
      getEventsLocations()
    }

    return () => {
      setRegionsIds([])
      setCategoriesIds([])
      setSpeakersIds([])
      setBlogPostCategoriesIds([])
      setEventsLocationsIds([])
    }
  }, [])

  useEffect(() => {
    if (
      regionsList.length > 0 ||
      categoriesList.length > 0 ||
      speakersFull.length > 0 ||
      blogPostCategories.length > 0 ||
      eventsLocations.length > 0
    ) {
      const parseParams = parseSearchParams()

      if (parseParams.regions) {
        setRegionsIds(parseParams.regions.map((id) => +id))
      }

      if (parseParams.categories) {
        setCategoriesIds(parseParams.categories.map((id) => +id))
      }

      if (parseParams.speakers) {
        setSpeakersIds(parseParams.speakers.map((id) => +id))
      }

      if (parseParams.blogPostCategories) {
        setBlogPostCategoriesIds(parseParams.blogPostCategories.map((id) => +id))
      }

      if (parseParams.eventsLocations) {
        setEventsLocationsIds(parseParams.locations.map((id) => +id))
      }
    }
  }, [regionsList, categoriesList, speakersFull, blogPostCategories, eventsLocations])

  const updateDataList = (params) => {
    navigate({
      search: queryString.stringify(params, { arrayFormat: 'bracket' }),
    })

    onRequest({ ...params, ...customParams })
  }

  const handleChangeRegions = (selectedIds) => {
    const params = {
      regions: selectedIds,
      categories: categoriesIds,
      speakers: speakersIds,
      blogPostCategories: blogPostCategoriesIds,
      locations: eventsLocationsIds,
    }

    setRegionsIds(selectedIds)
    updateDataList(params)
  }

  const handleChangeCategories = (selectedIds) => {
    const params = {
      regions: regionsIds,
      categories: selectedIds,
      speakers: speakersIds,
      blogPostCategories: blogPostCategoriesIds,
      locations: eventsLocationsIds,
    }

    setCategoriesIds(selectedIds)
    updateDataList(params)
  }

  const handleChangeSpeakers = (selectedIds) => {
    const params = {
      regions: regionsIds,
      categories: categoriesIds,
      speakers: selectedIds,
      blogPostCategories: blogPostCategoriesIds,
      locations: eventsLocationsIds,
    }

    setSpeakersIds(selectedIds)
    updateDataList(params)
  }

  const handleBlogPostCategories = (selectedIds) => {
    const params = {
      regions: regionsIds,
      categories: categoriesIds,
      speakers: speakersIds,
      blogPostCategories: selectedIds,
      locations: eventsLocationsIds,
    }

    setBlogPostCategoriesIds(selectedIds)
    updateDataList(params)
  }

  const handleEventsLocations = (selectedIds) => {
    const params = {
      regions: regionsIds,
      categories: categoriesIds,
      speakers: speakersIds,
      blogPostCategories: blogPostCategoriesIds,
      locations: selectedIds,
    }

    setEventsLocationsIds(selectedIds)
    updateDataList(params)
  }

  const intitialDefaultActiveKeys = () => {
    const keys = []

    if (visibleRegions) {
      keys.push('regions')
    }

    if (visibleCategories) {
      keys.push('categories')
    }

    if (visibleSpeakers) {
      keys.push('speakers')
    }

    if (visibleBlogPostCategories) {
      keys.push('blogPostCategories')
    }

    if (visibleEventsLocations) {
      keys.push('eventsLocations')
    }

    return keys
  }

  const filterList = (
    <Collapse defaultActiveKey={intitialDefaultActiveKeys()} expandIconPosition='right' ghost>
      {visibleRegions && (
        <Panel header='Region' key='regions'>
          <Checkbox.Group
            options={regionsList
              .filter((item) => {
                if (context === 'speakers') {
                  return item.speakers_count > 0
                }

                if (context === 'webinars') {
                  return item.recorded_webinar_count > 0
                }

                return true
              })
              .map((region) => ({
                label: (
                  <Filter.Title>
                    <Filter.Item>{region.title}</Filter.Item>
                    {context === 'speakers' && <Filter.Item>({region.speakers_count})</Filter.Item>}
                    {context === 'webinars' && <Filter.Item>({region.recorded_webinar_count})</Filter.Item>}
                  </Filter.Title>
                ),
                value: region.id,
              }))}
            value={regionsIds}
            onChange={handleChangeRegions}
          />
        </Panel>
      )}

      {visibleCategories && (
        <Panel header='Category' key='categories'>
          <Checkbox.Group
            options={categoriesList.map((category) => ({
              label: (
                <Filter.Title>
                  <Filter.Item>{category.title}</Filter.Item>
                  <Filter.Item>({category.recorded_webinar_count})</Filter.Item>
                </Filter.Title>
              ),
              value: category.id,
            }))}
            value={categoriesIds}
            onChange={handleChangeCategories}
          />
        </Panel>
      )}

      {visibleSpeakers && (
        <Panel header='Speaker' key='speakers'>
          <Checkbox.Group
            options={speakersFull
              .filter((item) => {
                if (context === 'events') {
                  return item.events_count > 0
                }

                if (context === 'webinars') {
                  return item.webinars_count > 0
                }

                return true
              })
              .slice(0, showAllSpeakers ? speakersFull.length : 10)
              .map((speaker) => ({
                label: (
                  <Filter.Title>
                    <Filter.Item>{speaker.name}</Filter.Item>
                    {context === 'events' && <Filter.Item>({speaker.events_count})</Filter.Item>}
                    {context === 'webinars' && <Filter.Item>({speaker.webinars_count})</Filter.Item>}
                  </Filter.Title>
                ),
                value: speaker.id,
              }))}
            value={speakersIds}
            onChange={handleChangeSpeakers}
          />
          {speakersFull.length > 10 && (
            <Filter.ExpandButton type='button' onClick={() => setShowAllSpeakers((value) => !value)}>
              {showAllSpeakers ? 'Hide' : 'See more'}
            </Filter.ExpandButton>
          )}
        </Panel>
      )}

      {visibleBlogPostCategories && (
        <Panel header='Category' key='blogPostCategories'>
          <Checkbox.Group
            options={blogPostCategories.map((category) => ({
              label: (
                <Filter.Title>
                  <Filter.Item>{category.title}</Filter.Item>
                  <Filter.Item>({category.count})</Filter.Item>
                </Filter.Title>
              ),
              value: category.id,
            }))}
            value={blogPostCategoriesIds}
            onChange={handleBlogPostCategories}
          />
        </Panel>
      )}

      {visibleEventsLocations && (
        <Panel header='Location' key='eventsLocations'>
          <Checkbox.Group
            options={eventsLocations.map((item) => ({
              label: (
                <Filter.Title>
                  <Filter.Item>{item.title}</Filter.Item>
                  <Filter.Item>({item.count})</Filter.Item>
                </Filter.Title>
              ),
              value: encodeURIComponent(item.title.toLowerCase()),
            }))}
            value={eventsLocationsIds}
            onChange={handleEventsLocations}
          />
        </Panel>
      )}
    </Collapse>
  )

  return (
    <Filter>
      {filterVisible && (
        <Modal
          width='100%'
          className='modal-filter-wide'
          onCancel={() => setFilterVisible(false)}
          footer={null}
          transitionName='none'
          visible
        >
          <Title level={3}>Filter by</Title>

          {filterList}

          <Button type='primary' onClick={() => setFilterVisible(false)} block>
            Filter
          </Button>
        </Modal>
      )}

      {screens.xl ? (
        <>
          <Title level={5}>Filter by</Title>
          {filterList}
        </>
      ) : (
        <Filter.Mobile>
          <Button type='primary' onClick={() => setFilterVisible(true)} block>
            Filter by <Filter.Icon src={images.iconFilter} />
          </Button>
        </Filter.Mobile>
      )}
    </Filter>
  )
}

export default FilterBy
