import React, { useCallback } from 'react'
import { useActions } from '../../../hooks'
import { auth as authActions } from '../../../store/actions'
import ResetPasswordPage from './ResetPasswordPage'

const ResetPasswordPageContainer = () => {
  const [authDispatch] = useActions([authActions])
  const authResetPassword = useCallback(authDispatch.authResetPassword, [authDispatch])
  const resetAuth = useCallback(authDispatch.resetAuth, [authDispatch])

  const props = {
    authResetPassword,
    resetAuth,
  }

  return <ResetPasswordPage {...props} />
}

export default ResetPasswordPageContainer
