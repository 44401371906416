import React, { useEffect } from 'react'
import { Row, Col, Avatar, Typography, Grid } from 'antd'
import { useParams } from 'react-router-dom'
import { images } from '../../../assets'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'
import { theme } from '../../../styles'
import { LayoutCentering, Ellipse, Loader } from '../../components'
import OtherPreviousWebinars from './components/OtherPreviousWebinars'
import { Speaker } from './styled'

const { useBreakpoint } = Grid
const { Title } = Typography

const SpeakerDetailsPage = ({ getSpeakerDetails }) => {
  const params = useParams()
  const screens = useBreakpoint()

  const { isFetchingSpeakerDetails, speakerDetails } = useShallowEqualSelector((state) => state.speakers)

  useEffect(() => {
    getSpeakerDetails(params.slug)
  }, [])

  useDocumentTitle(speakerDetails?.name)

  if (!speakerDetails.id || isFetchingSpeakerDetails) {
    return <Loader />
  }

  return (
    <Speaker>
      <Speaker.Inner>
        <Speaker.CircleGreen>
          <Ellipse type='circle' color={theme.colors.green[200]} size={15.3} />
        </Speaker.CircleGreen>

        <Speaker.SemiRing>
          <Ellipse type='semiring' color={theme.colors.yellow[200]} size={25} rotate={-45} />
        </Speaker.SemiRing>

        <LayoutCentering>
          {screens.xl && (
            <Speaker.Header>
              <Title level={2}>{speakerDetails.name}</Title>
            </Speaker.Header>
          )}

          <Row gutter={[60, 40]}>
            <Col xl={9} xs={24}>
              <Speaker.Details>
                <Speaker.Details.Pacman>
                  <Ellipse type='pacman' color={theme.colors.yellow[200]} size={5.2} />
                </Speaker.Details.Pacman>

                <Speaker.Details.SemiCircle>
                  <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={6} rotate={-45} />
                </Speaker.Details.SemiCircle>

                <Speaker.Details.SemiRing>
                  <Ellipse type='semiring' color={theme.colors.violet[200]} size={6} rotate={-45} />
                </Speaker.Details.SemiRing>

                <Speaker.Item>
                  <Avatar size={160} src={speakerDetails.image} />

                  <Speaker.Item.Info>
                    {speakerDetails.company && (
                      <Speaker.Item.Details>
                        <Speaker.Item.Details.Name>
                          <Speaker.Item.Details.Icon src={images.iconDate} />
                          <Speaker.Item.Details.Label>Company name</Speaker.Item.Details.Label>
                        </Speaker.Item.Details.Name>

                        <Speaker.Item.Details.Value>{speakerDetails.company}</Speaker.Item.Details.Value>
                      </Speaker.Item.Details>
                    )}

                    <Speaker.Item.Details>
                      <Speaker.Item.Details.Name>
                        <Speaker.Item.Details.Icon src={images.iconRegion} />
                        <Speaker.Item.Details.Label>Region</Speaker.Item.Details.Label>
                      </Speaker.Item.Details.Name>

                      <Speaker.Item.Details.Value>{speakerDetails.region.title}</Speaker.Item.Details.Value>
                    </Speaker.Item.Details>
                  </Speaker.Item.Info>
                </Speaker.Item>
              </Speaker.Details>
            </Col>

            <Col xl={15} xs={24}>
              {!screens.xl && (
                <Speaker.Header>
                  <Title level={2}>{speakerDetails.name}</Title>
                </Speaker.Header>
              )}

              <Speaker.Description dangerouslySetInnerHTML={{ __html: speakerDetails.description }} />
            </Col>
          </Row>
        </LayoutCentering>
      </Speaker.Inner>

      <OtherPreviousWebinars slug={params.slug} />
    </Speaker>
  )
}

export default SpeakerDetailsPage
