import React from 'react'
import { Grid, Typography } from 'antd'
import { images } from '../../../../../assets'
import { theme } from '../../../../../styles'
import { LayoutCentering, CustomCarousel, Ellipse } from '../../../../components'
import { Description, Item } from './styled'

const { useBreakpoint } = Grid
const { Title } = Typography

const staticList = [
  {
    image: images.hiwStep1,
    details: 'Click the create an account button.',
  },
  {
    image: images.hiwStep2,
    details: 'Create an account and fill in the details.',
  },
  {
    image: images.hiwStep3,
    details: 'Activate your account via the email verification link.',
  },
  {
    image: images.hiwStep4,
    details: 'Login to your account.',
  },
  {
    image: images.hiwStep5,
    details: 'Sign up to upcoming webinars, or watch past recordings.',
  },
  {
    image: images.hiwStep6,
    details: 'Earn CPD points, keep on track with your learning.',
  },
]

const MainWorkDescription = () => {
  const screens = useBreakpoint()

  return (
    <Description>
      <Description.Circle>
        <Ellipse type='circle' color={theme.colors.green[200]} size={screens.lg ? 15.3 : 5.7} />
      </Description.Circle>

      <Description.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3 ? 15.3 : 10} rotate={-45} />
      </Description.SemiCircle>

      <LayoutCentering>
        <Title>How it works?</Title>

        {screens.md ? (
          <Description.Inner>
            {staticList.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Item key={i}>
                <Item.Wrapper>
                  <Item.Image src={item.image} />
                </Item.Wrapper>

                <Item.Text>
                  <Item.Number>{i + 1}</Item.Number>
                  <span>{item.details}</span>
                </Item.Text>
              </Item>
            ))}
          </Description.Inner>
        ) : (
          <CustomCarousel>
            {staticList.map((item, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Item key={i}>
                <Item.Wrapper>
                  <Item.Image src={item.image} />
                </Item.Wrapper>

                <Item.Text>
                  <Item.Number>{i + 1}</Item.Number>
                  <span>{item.details}</span>
                </Item.Text>
              </Item>
            ))}
          </CustomCarousel>
        )}
      </LayoutCentering>
    </Description>
  )
}

export default MainWorkDescription
