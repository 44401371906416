import React, { useEffect } from 'react'
import { LeftOutlined } from '@ant-design/icons'
import { Row, Col, Form, Checkbox, Input, Button, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { animations } from '../../../assets'
import { regexp } from '../../../helpers'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'
import { MessageProcess } from '../../components'
import { Register } from './styled'

const { Title, Text } = Typography

const RegisterPage = ({ authRegister, resetAuth }) => {
  const { isFetchingRegister, isRegistered } = useShallowEqualSelector((state) => state.auth)

  const [form] = Form.useForm()

  useEffect(() => {
    return () => {
      resetAuth()
    }
  }, [])

  useDocumentTitle('Register')

  const onFinish = (values) => authRegister(values)

  if (isRegistered) {
    const registerEmail = form.getFieldValue('email')

    return (
      <MessageProcess
        title='Thank you for registering!'
        animationData={animations.email}
        details={
          <>
            <Register.Message>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Please check your email and click on the activation link that has been sent to you. If you don't see this,
              please check your spam / junk folder.
            </Register.Message>
            <Register.Message>
              An email has been sent to: <Text type='danger'>{registerEmail}</Text>
            </Register.Message>
          </>
        }
      />
    )
  }

  return (
    <Register>
      <Register.Back to='/'>
        <LeftOutlined /> Go back
      </Register.Back>

      <Register.Heading>
        <Title level={2}>Create an account</Title>
        <Text>
          Create an account below to gain access to all previous webinars, register for upcoming live events and keep
          track of your learning via our virtual CPD log.
        </Text>
      </Register.Heading>

      <Form form={form} onFinish={onFinish} autoComplete='off' layout='vertical'>
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='First name'
              name='first_name'
              rules={[
                {
                  required: true,
                  message: 'Please enter your first name',
                },
              ]}
            >
              <Input placeholder='First name' />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              label='Last name'
              name='last_name'
              rules={[
                {
                  required: true,
                  message: 'Please enter your last name',
                },
              ]}
            >
              <Input placeholder='Last name' />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label='Job title'
          name='job_title'
          rules={[
            {
              required: true,
              message: 'Please enter job title',
            },
          ]}
        >
          <Input placeholder='Job title' />
        </Form.Item>

        <Form.Item
          label='Company'
          name='company'
          rules={[
            {
              required: true,
              message: 'Please enter company name',
            },
          ]}
        >
          <Input placeholder='Company name' />
        </Form.Item>

        <Form.Item
          label='Email address'
          name='email'
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please enter your email',
            },
          ]}
        >
          <Input placeholder='Email address' />
        </Form.Item>

        <Form.Item
          label='Phone number'
          name='phone'
          rules={[
            {
              required: true,
              message: 'Please enter your phone number',
            },
            {
              min: 9,
              message: 'Phone number must be at least 9 digits',
            },
            {
              max: 14,
              message: 'Phone number must be no more than 14 digits',
            },
          ]}
        >
          <Input type='number' placeholder='Phone number' />
        </Form.Item>

        <Form.Item
          label='Create new password'
          name='password'
          rules={[
            {
              required: true,
              message: 'Please enter your password',
            },
            {
              min: 8,
            },
            {
              pattern: regexp.passwordPattern(),
            },
          ]}
          help={
            <Register.HelpText>
              At least 8 characters (including a lower and uppercase letter, number and special character)
            </Register.HelpText>
          }
        >
          <Input.Password placeholder='Password' autoComplete='new-password' />
        </Form.Item>

        <Form.Item
          label='Confirm Password'
          name='password_confirmation'
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The entered passwords do not match'))
              },
            }),
          ]}
        >
          <Input.Password placeholder='Confirm password' />
        </Form.Item>

        <Form.Item
          name='agreement'
          valuePropName='checked'
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('You must agree to the site policy')),
            },
          ]}
        >
          <Checkbox>
            <Register.FormItem>
              I have read and accept{' '}
              <Link to='/privacy-policy' target='_blank'>
                privacy policy
              </Link>
            </Register.FormItem>
          </Checkbox>
        </Form.Item>

        <Form.Item style={{ marginTop: '1.6rem' }}>
          <Button type='primary' htmlType='submit' loading={isFetchingRegister} block>
            Create an Account
          </Button>
        </Form.Item>

        <Register.ToLogin>
          Already have an account?{' '}
          <Text strong>
            <Link to='/login'>Log in</Link>
          </Text>
        </Register.ToLogin>
      </Form>
    </Register>
  )
}

export default RegisterPage
