import React, { useCallback } from 'react'
import { useActions } from '../../../hooks'
import { webinars as webinarsActions, history as historyActions } from '../../../store/actions'
import WebinarDetailsPage from './WebinarDetailsPage'

const WebinarDetailsPageContainer = () => {
  const [webinarsDispatch] = useActions([webinarsActions])
  const getWebinarDetails = useCallback(webinarsDispatch.getWebinarDetails, [webinarsDispatch])

  const [historyDispatch] = useActions([historyActions])
  const storeHistoryLog = useCallback(historyDispatch.storeHistoryLog, [historyDispatch])

  const props = {
    getWebinarDetails,
    storeHistoryLog,
  }

  return <WebinarDetailsPage {...props} />
}

export default WebinarDetailsPageContainer
