import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Checkbox } from 'antd'
import { useCookie, useTimeout } from 'react-use'
import { useShallowEqualSelector } from '../../../hooks'
import { Training, ModalTitle, Separator } from './styled'

const ModalReferACase = ({ submitForm }) => {
  const [form] = Form.useForm()

  const { isLogged, profileDetails } = useShallowEqualSelector((state) => state.auth)
  const { isFetchingSendFeedback } = useShallowEqualSelector((state) => state.feedback)
  const { settings } = useShallowEqualSelector((state) => state.panels)
  const [visible, setVisible] = useState(false)
  const [dismissed, setDismissed] = useCookie('refer-a-case-dismissed-2')
  const [isReady] = useTimeout(10000)
  const ready = isReady()

  useEffect(() => {
    if (isLogged && profileDetails.id) {
      form.setFieldsValue({
        ...profileDetails,
        name: `${profileDetails.first_name} ${profileDetails.last_name}`,
      })
    }
  }, [isLogged, profileDetails.id])

  useEffect(() => {
    if (settings.refer_a_case_modal_enabled && !dismissed && ready) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }, [settings.refer_a_case_modal_enabled, dismissed, ready])

  const onCancel = () => setDismissed(true, { expires: 30 })

  const onFinish = (values) => {
    submitForm(values, form)
    onCancel()
  }

  return (
    <Modal
      title={<ModalTitle>Do you require our assistance?</ModalTitle>}
      open={visible}
      onCancel={onCancel}
      footer={null}
      afterClose={() => form.resetFields()}
      centered
    >
      <Training>
        <p>
          Whilst we are providing this learning hub and the content for free, our core business is locating heirs and
          beneficiaries worldwide.
        </p>
        <Separator />
        <Form form={form} onFinish={onFinish} autoComplete='off' layout='vertical'>
          <Form.Item
            label='Tick any of the following and we will offer assistance:'
            name='assistance'
            rules={[
              {
                required: true,
                message: 'Please select at least one option',
              },
            ]}
          >
            <Checkbox.Group
              options={[
                'I require a FREE family tree check?',
                'I have a case with no known kin?',
                'I have a case with missing beneficiaries?',
                'I’m interested in hearing about Estate Research services in more detail and would like to arrange a meeting (either in-person or virtual).',
                'Other e.g. overseas enquiries',
              ]}
            />
          </Form.Item>

          <Separator />

          <Form.Item
            label='Full name'
            name='name'
            rules={[
              {
                required: true,
                message: 'Please enter your name',
              },
            ]}
          >
            <Input placeholder='Name' />
          </Form.Item>

          <Form.Item
            label='Company name'
            name='company'
            rules={[
              {
                required: true,
                message: 'Please enter company name',
              },
            ]}
          >
            <Input placeholder='Company name' />
          </Form.Item>

          <Form.Item
            label='Email address'
            name='email'
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please enter your email',
              },
            ]}
          >
            <Input placeholder='Your email address' />
          </Form.Item>

          <Form.Item
            label='Phone'
            name='phone'
            rules={[
              {
                required: true,
                message: 'Please enter your phone',
              },
            ]}
          >
            <Input placeholder='Phone' />
          </Form.Item>

          <Separator />

          <Form.Item
            name='consent'
            valuePropName='checked'
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('The consent is required')),
              },
            ]}
          >
            <Checkbox>
              By submitting this form you agree to be contacted by Estate Research. We will not pass your details to
              third parties without prior consent.
            </Checkbox>
          </Form.Item>

          <Training.Submit>
            <Form.Item noStyle>
              <Button type='link' onClick={onCancel}>
                Cancel
              </Button>

              <Button type='primary' htmlType='submit' loading={isFetchingSendFeedback}>
                Send enquiry
              </Button>
            </Form.Item>
          </Training.Submit>
        </Form>
      </Training>
    </Modal>
  )
}

export default ModalReferACase
