import React, { useEffect } from 'react'
import { CheckOutlined } from '@ant-design/icons'
import { Grid, Typography } from 'antd'
import { useDocumentTitle, useShallowEqualSelector } from '../../../../../hooks'
import { Loader, CustomCarousel, WebinarsContinueWatchingItem } from '../../../../components'
import { Overview } from './styled'

const { Title, Text } = Typography
const { useBreakpoint } = Grid

const AccountOverview = ({ getOverviewStatistics, getWebinarsInProgress }) => {
  const screens = useBreakpoint()

  const { isFetchingOverviewStatistics, overviewStatistics } = useShallowEqualSelector((state) => state.statistics)
  const { inProgressWebinars } = useShallowEqualSelector((state) => state.webinars)

  useEffect(() => {
    getOverviewStatistics()
    getWebinarsInProgress({})
  }, [])

  useDocumentTitle('Overview')

  const numberWebinarsShown = 2

  const shortNumberList = [0, 3, 5, 7, 10, 13, 15, 17, 20]

  if (isFetchingOverviewStatistics) {
    return <Loader />
  }

  return (
    <Overview>
      <Overview.Card>
        <Title level={3}>Your Estate Research Learning Hub Progress</Title>

        <Overview.Stats>
          <Overview.Stat>
            <Overview.Stat.Number>{overviewStatistics.live_webinars_attendend || 0}</Overview.Stat.Number>

            <Text type='secondary'>
              Total{' '}
              <Text type='danger' strong>
                live
              </Text>
              <br />
              webinars attended
            </Text>
          </Overview.Stat>

          <Overview.Stat>
            <Overview.Stat.Number>{overviewStatistics.recorded_webinars_full_watched || 0}</Overview.Stat.Number>

            <Text type='secondary'>
              Total{' '}
              <Text type='warning' strong>
                recorded
              </Text>
              <br />
              webinars watched
            </Text>
          </Overview.Stat>

          <Overview.Stat>
            <Overview.Stat.Number>{overviewStatistics.total_time_formatted || 0}</Overview.Stat.Number>
            {screens.xs ? (
              <Text type='secondary'>
                Total learning
                <br />
                time with Estate
                <br />
                Research in {new Date().getFullYear()}
              </Text>
            ) : (
              <Text type='secondary'>
                Total learning time with
                <br />
                Estate Research in {new Date().getFullYear()}
              </Text>
            )}
          </Overview.Stat>
        </Overview.Stats>

        <Text>
          <Text strong>Estate Research Learning Hub Hours</Text> (Jan {new Date().getFullYear()} - Dec{' '}
          {new Date().getFullYear()})
        </Text>

        <Overview.Progress>
          <Overview.Progress.Bar>
            <Overview.Progress.Process
              style={{
                width:
                  overviewStatistics.total_hours >= 20
                    ? '100%'
                    : `${(100 / 21) * (overviewStatistics.total_hours + 1) - 100 / 21 / 2}%`,
              }}
            >
              <Overview.Progress.Current>
                {overviewStatistics.total_hours >= 20 ? <CheckOutlined /> : overviewStatistics.total_hours}
              </Overview.Progress.Current>
            </Overview.Progress.Process>
          </Overview.Progress.Bar>

          <Overview.Progress.Counts>
            {screens.md &&
              [...Array.from({ length: 21 }).keys()].map((number) => (
                <span key={number} style={{ opacity: number === overviewStatistics.total_hours ? 0 : 1 }}>
                  {number}
                </span>
              ))}

            {!screens.md &&
              shortNumberList.map((number) => (
                <span key={number} style={{ opacity: number === overviewStatistics.total_hours ? 0 : 1 }}>
                  {number}
                </span>
              ))}
          </Overview.Progress.Counts>
        </Overview.Progress>
      </Overview.Card>

      {inProgressWebinars.list.length > 0 && (
        <Overview.Card isLast>
          <Title level={3}>Continue Watching</Title>

          <Overview.List>
            <CustomCarousel
              slidesToShow={screens.md ? numberWebinarsShown : 1}
              infinite={inProgressWebinars.list.length >= numberWebinarsShown}
              hideArrows={
                (screens.lg && inProgressWebinars.list.length <= numberWebinarsShown) ||
                inProgressWebinars.list.length <= 1
              }
            >
              {inProgressWebinars.list.map((item) => (
                <Overview.Item key={item.id}>
                  <WebinarsContinueWatchingItem item={item} />
                </Overview.Item>
              ))}
            </CustomCarousel>
          </Overview.List>
        </Overview.Card>
      )}
    </Overview>
  )
}

export default AccountOverview
