import React, { useCallback } from 'react'
import { useActions } from '../../../hooks'
import { events as eventsAction } from '../../../store/actions'
import EventsPage from './EventsPage'

const EventsPageContainer = () => {
  const [eventsDispatch] = useActions([eventsAction])
  const getEvents = useCallback(eventsDispatch.getEvents, [eventsDispatch])

  const props = {
    getEvents,
  }

  return <EventsPage {...props} />
}

export default EventsPageContainer
