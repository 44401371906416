import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Header, Footer } from '../../components'
import { Layout, Content } from './styled'

const MainLayout = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Layout>
      <Header />

      <Content>
        <Outlet />
      </Content>

      <Footer />
    </Layout>
  )
}

export default MainLayout
