import React, { useCallback } from 'react'
import { useActions } from '../../../../../hooks'
import { speakers as speakersActions } from '../../../../../store/actions'
import WebinarsFeaturedSpeakers from './WebinarsFeaturedSpeakers'

const WebinarsFeaturedSpeakersContainer = () => {
  const [speakersDispatch] = useActions([speakersActions])
  const getSpeakersList = useCallback(speakersDispatch.getSpeakersList, [speakersDispatch])

  const props = {
    getSpeakersList,
  }

  return <WebinarsFeaturedSpeakers {...props} />
}

export default WebinarsFeaturedSpeakersContainer
