import React, { useCallback } from 'react'
import { useActions } from '../../../../../hooks'
import { statistics as statisticsActions, webinars as webinarsActions } from '../../../../../store/actions'
import AccountOverview from './AccountOverview'

const AccountOverviewContainer = () => {
  const [statisticsDispatch] = useActions([statisticsActions])
  const getOverviewStatistics = useCallback(statisticsDispatch.getOverviewStatistics, [statisticsDispatch])

  const [webinarsDispatch] = useActions([webinarsActions])
  const getWebinarsInProgress = useCallback(webinarsDispatch.getWebinarsInProgress, [webinarsDispatch])

  const props = {
    getOverviewStatistics,
    getWebinarsInProgress,
  }

  return <AccountOverview {...props} />
}

export default AccountOverviewContainer
