import React, { useEffect } from 'react'
import { Col, Grid, Row, Table, Tag, Typography } from 'antd'
import { useParams } from 'react-router-dom'
import { images } from '../../../assets'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'
import { theme } from '../../../styles'
import { Ellipse, LayoutCentering, Loader, SpeakerItem } from '../../components'
import OtherPreviousWebinars from './components/OtherPreviousWebinars'
import { Webinar } from './styled'

const { useBreakpoint } = Grid
const { Title } = Typography

let interval = null

const replaceEmbeded = (embeded) => {
  const strH = embeded.indexOf('?h')
  const strVideo = embeded.indexOf('/video/')

  // eslint-disable-next-line unicorn/prefer-string-slice
  return embeded.slice(0, Math.max(0, strH)).substr(strVideo + 7, 9)
}

const handleDownloadFile = (file) => {
  const downloadLink = document.createElement('a')

  downloadLink.href = file.download_url
  downloadLink.download = `${file.name}.${file.extension}`

  document.body.append(downloadLink)
  downloadLink.click()
  downloadLink.remove()
}

const WebinarDetailsPage = ({ getWebinarDetails, storeHistoryLog }) => {
  const params = useParams()
  const screens = useBreakpoint()

  const { isFetchingWebinarDetails, webinarDetails } = useShallowEqualSelector((state) => state.webinars)

  useEffect(() => {
    getWebinarDetails(params.slug)

    return () => {
      clearInterval(interval)
    }
  }, [params.id])

  useDocumentTitle(webinarDetails.title)

  const handleDispatchTime = (time) => {
    storeHistoryLog({
      webinar_id: webinarDetails.id,
      completion: +(time.percent * 100).toFixed(3),
      view_time: time.seconds / 60,
    })
  }

  const handleChangeTime = (time) => {
    if (!interval) {
      interval = setInterval(() => {
        handleDispatchTime(time)
        clearInterval(interval)
        interval = null
      }, 10000)
    }
  }

  const handleEndTime = (time) => {
    if (interval) {
      clearInterval(interval)
      interval = null
    }
    handleDispatchTime(time)
  }

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'extension',
      key: 'extension',
      width: 100,
      render: (extension) => `.${extension}`,
    },
    {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (record) => (
        <Webinar.Download onClick={() => handleDownloadFile(record)}>
          Download <Webinar.Download.Icon src={images.iconDownload} />
        </Webinar.Download>
      ),
    },
  ]

  if (!webinarDetails.id || isFetchingWebinarDetails) {
    return <Loader />
  }

  return (
    <Webinar>
      <Webinar.Inner>
        <Webinar.CircleGreen>
          <Ellipse type='circle' color={theme.colors.green[200]} size={15.3} />
        </Webinar.CircleGreen>

        <Webinar.SemiRing>
          <Ellipse type='semiring' color={theme.colors.yellow[200]} size={25} rotate={-45} />
        </Webinar.SemiRing>

        <LayoutCentering>
          {screens.xl && (
            <Webinar.Header withTag={+webinarDetails.completion === 0}>
              <Title level={2}>{webinarDetails.title}</Title>
              {+webinarDetails.completion >= 99 && (
                <Webinar.Tag>
                  <Tag color={theme.colors.green[200]}>
                    <Webinar.Tag.Icon src={images.iconTagWatched} /> Watched
                  </Tag>
                </Webinar.Tag>
              )}

              {+webinarDetails.completion !== 0 && +webinarDetails.completion < 99 && (
                <Webinar.Tag>
                  <Tag color={theme.colors.lightBlue[200]}>
                    <Webinar.Tag.Icon src={images.iconTagContinueWatching} /> Continue Watching
                  </Tag>
                </Webinar.Tag>
              )}
            </Webinar.Header>
          )}

          <Row gutter={[60, 32]}>
            <Col xl={7} xs={24}>
              <Webinar.Details>
                <Webinar.Details.Pacman>
                  <Ellipse type='pacman' color={theme.colors.yellow[200]} size={5.2} />
                </Webinar.Details.Pacman>

                <Webinar.Details.SemiRing>
                  <Ellipse type='semiring' color={theme.colors.violet[200]} size={6} rotate={-45} />
                </Webinar.Details.SemiRing>

                {webinarDetails.speakers.map((item) => (
                  <SpeakerItem
                    key={item.id}
                    item={item}
                    start={webinarDetails.start_time}
                    region={webinarDetails.region}
                    categoriesList={webinarDetails.categories}
                    nostyle
                    hideRegion
                    bottomLine
                  />
                ))}
              </Webinar.Details>
            </Col>

            <Col xl={17} xs={24}>
              {!screens.xl && (
                <Webinar.Header>
                  <Title level={2}>{webinarDetails.title}</Title>
                  {+webinarDetails.completion >= 99 && (
                    <Webinar.Tag>
                      <Tag color={theme.colors.green[200]}>
                        <Webinar.Tag.Icon src={images.iconTagWatched} /> Watched
                      </Tag>
                    </Webinar.Tag>
                  )}

                  {+webinarDetails.completion !== 0 && +webinarDetails.completion < 99 && (
                    <Webinar.Tag>
                      <Tag color={theme.colors.lightBlue[200]}>
                        <Webinar.Tag.Icon src={images.iconTagContinueWatching} /> Continue Watching
                      </Tag>
                    </Webinar.Tag>
                  )}
                </Webinar.Header>
              )}

              <Webinar.Description>
                <div dangerouslySetInnerHTML={{ __html: webinarDetails.description }} />

                {webinarDetails.embeded && (
                  <Webinar.Video
                    start={((webinarDetails.duration * 60) / 100) * webinarDetails.completion}
                    video={replaceEmbeded(webinarDetails.embeded)}
                    onTimeUpdate={handleChangeTime}
                    responsive
                    onEnd={handleEndTime}
                    onSeeked={handleDispatchTime}
                  />
                )}

                {webinarDetails.files.length > 0 ? (
                  <Webinar.Documents withAdvert={webinarDetails.advertisement}>
                    <Title level={5}>Supporting Documents</Title>

                    {!screens.xs && (
                      <Table
                        rowKey={(row) => row.uuid}
                        dataSource={webinarDetails.files}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: '48rem' }}
                      />
                    )}

                    {screens.xs &&
                      webinarDetails.files.map((document) => (
                        <Webinar.Document key={document.uuid}>
                          <Webinar.Document.Title>
                            <div>File Name</div>
                            <div>Type</div>
                          </Webinar.Document.Title>
                          <Webinar.Document.Name>
                            <div>{document.name}</div>
                            <div>.{document.extension}</div>
                          </Webinar.Document.Name>
                          <Webinar.Document.Download>
                            <Webinar.Download onClick={() => handleDownloadFile(document)}>
                              Download <Webinar.Download.Icon src={images.iconDownload} />
                            </Webinar.Download>
                          </Webinar.Document.Download>
                        </Webinar.Document>
                      ))}
                  </Webinar.Documents>
                ) : null}

                {webinarDetails.advertisement && (
                  <Webinar.Advertisement href={webinarDetails.advertisement.url} target='_blank'>
                    <Webinar.Advertisement.Picture
                      src={
                        screens.md
                          ? webinarDetails.advertisement.desktop_image
                          : webinarDetails.advertisement.mobile_image
                      }
                    />
                  </Webinar.Advertisement>
                )}
              </Webinar.Description>
            </Col>
          </Row>
        </LayoutCentering>
      </Webinar.Inner>

      <OtherPreviousWebinars categoriesIds={webinarDetails.categories.map((category) => category.id)} />
    </Webinar>
  )
}

export default WebinarDetailsPage
