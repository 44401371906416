import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useShallowEqualSelector } from '../../../hooks'
import { Header, Footer } from '../../components'
import ModalReferACaseContainer from '../../components/ModalReferACase'
import { Layout, Content } from './styled'

const LoggedLayout = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { isLogged } = useShallowEqualSelector((state) => state.auth)

  useEffect(() => {
    if (!isLogged) {
      navigate('/', { replace: true })
    }
  }, [isLogged])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Layout>
      <Header />

      <Content>
        <Outlet />
      </Content>

      <ModalReferACaseContainer />

      <Footer />
    </Layout>
  )
}

export default LoggedLayout
