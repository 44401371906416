import React, { useCallback } from 'react'
import { useActions } from '../../../hooks'
import { webinars as webinarsActions } from '../../../store/actions'
import GillSteelPage from './GillSteelPage'

const GillSteelPageContainer = () => {
  const [webinarsDispatch] = useActions([webinarsActions])
  const getWebinarsRecorded = useCallback(webinarsDispatch.getWebinarsRecorded, [webinarsDispatch])

  const props = {
    getWebinarsRecorded,
  }

  return <GillSteelPage {...props} />
}

export default GillSteelPageContainer
