import React, { useEffect } from 'react'
import { Typography, Grid, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useShallowEqualSelector } from '../../../../../hooks'
import { theme } from '../../../../../styles'
import {
  LayoutCentering,
  Ellipse,
  CustomCarousel,
  WebinarsPreviousList,
  WebinarPreviousItem,
} from '../../../../components'
import { Webinar } from '../../styled'
import { PreviousWebinars } from './styled'

const { Title } = Typography
const { useBreakpoint } = Grid

const OtherPreviousWebinars = ({ getWebinarsRecorded, categoriesIds }) => {
  const navigate = useNavigate()
  const screens = useBreakpoint()

  const { isFetchingRecordedWebinars, recordedWebinars } = useShallowEqualSelector((state) => state.webinars)

  useEffect(() => {
    getWebinarsRecorded(categoriesIds ? { categories: categoriesIds, per_page: 3 } : { per_page: 3 })
  }, [])

  return (
    <PreviousWebinars>
      <PreviousWebinars.SemiRing>
        <Ellipse type='semiring' color={theme.colors.red[200]} size={18} rotate={-45} />
      </PreviousWebinars.SemiRing>

      <Webinar.CircleYellow>
        <Ellipse type='circle' color={theme.colors.yellow[200]} size={10} />
      </Webinar.CircleYellow>

      <LayoutCentering>
        <PreviousWebinars.Header>
          <Title level={2}>Other webinars you may like</Title>

          {screens.lg && (
            <Button type='primary' onClick={() => navigate('/previous-webinars')}>
              View all webinars
            </Button>
          )}
        </PreviousWebinars.Header>

        {screens.lg ? (
          <WebinarsPreviousList loading={isFetchingRecordedWebinars} dataSource={recordedWebinars.list} />
        ) : (
          <>
            <CustomCarousel>
              {recordedWebinars.list.map((item) => (
                <WebinarPreviousItem key={item.id} item={item} />
              ))}
            </CustomCarousel>

            <PreviousWebinars.More>
              <Button type='primary' onClick={() => navigate('/previous-webinars')} block>
                View all webinars
              </Button>
            </PreviousWebinars.More>
          </>
        )}
      </LayoutCentering>
    </PreviousWebinars>
  )
}

export default OtherPreviousWebinars
