import React from 'react'
import { Button, DatePicker, Form, Input, InputNumber, Modal, Select } from 'antd'
import { useShallowEqualSelector } from '../../../../../hooks'
import { Training, ModalTitle } from './styled'

const ModalAddTrainingContainer = ({ visible, onCancel, createCPDLog }) => {
  const [form] = Form.useForm()

  const { isFetchingCreateCPDLog } = useShallowEqualSelector((state) => state.history)

  const onFinish = (values) => createCPDLog(values, onCancel)

  return (
    <Modal
      title={<ModalTitle>Add custom Training Event</ModalTitle>}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      afterClose={() => form.resetFields()}
      centered
    >
      <Training>
        <Form form={form} onFinish={onFinish} autoComplete='off' layout='vertical'>
          <Form.Item
            label='Title'
            name='title'
            rules={[
              {
                required: true,
                message: 'Please enter your title',
              },
            ]}
          >
            <Input placeholder='Title' />
          </Form.Item>

          <Form.Item
            label='Type'
            name='type'
            rules={[
              {
                required: true,
                message: 'Please enter your type',
              },
            ]}
          >
            <Select placeholder='Select'>
              <Select.Option value='LIVE'>Live</Select.Option>
              <Select.Option value='RECORDED'>Recorded</Select.Option>
              <Select.Option value='IN PERSON'>In person</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label='Presenter'
            name='presenter'
            rules={[
              {
                required: true,
                message: 'Please enter your presenter',
              },
            ]}
          >
            <Input placeholder='Full Name' />
          </Form.Item>

          <Form.Item
            label='Event Host'
            name='event_host'
            rules={[
              {
                required: true,
                message: 'Please enter your event host',
              },
            ]}
          >
            <Input placeholder='Event Host' />
          </Form.Item>

          <Form.Item
            label='Duration'
            name='duration'
            rules={[
              {
                required: true,
                message: 'Please enter your duration',
              },
            ]}
          >
            <InputNumber min={0} style={{ width: '100%' }} placeholder='60' />
          </Form.Item>
          <div className='ant-form-item-extra-custom'>In minutes</div>

          <Form.Item
            label='Date'
            name='time'
            rules={[
              {
                required: true,
                message: 'Please enter your date',
              },
            ]}
          >
            <DatePicker style={{ width: '100%' }} placeholder='DD/MM/YYYYY' />
          </Form.Item>

          <Form.Item
            label='Comments'
            name='comment'
            rules={[
              {
                required: true,
                message: 'Please enter your comments',
              },
            ]}
          >
            <Input.TextArea rows={4} placeholder='Start typing here...' />
          </Form.Item>

          <Training.Submit>
            <Form.Item noStyle>
              <Button type='link' onClick={onCancel}>
                Cancel
              </Button>

              <Button type='primary' htmlType='submit' loading={isFetchingCreateCPDLog}>
                Submit
              </Button>
            </Form.Item>
          </Training.Submit>
        </Form>
      </Training>
    </Modal>
  )
}

export default ModalAddTrainingContainer
