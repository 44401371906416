import React, { useEffect, useState } from 'react'
import { PlusSquareOutlined } from '@ant-design/icons'
import { Button, DatePicker, Input, Table, Tag, Typography } from 'antd'
import { debounce } from 'lodash'
import { ModalAddTraining, VideoModal } from '..'
import { images } from '../../../../../assets'
import { date } from '../../../../../helpers'
import { useDocumentTitle, useShallowEqualSelector } from '../../../../../hooks'
import { theme } from '../../../../../styles'
import { Log, ModalButton, Wrapper } from './styled'

const { Title, Text } = Typography

const AccountLog = ({ getHistoryCPDLog, updateCommentCPDLog, removeCPDLog, getHistoryCPDLogPdf }) => {
  const [modalAddVisible, setModalAddVisible] = useState(false)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [filterDate, setFilterDate] = useState({
    start_date: null,
    end_date: null,
  })

  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const { isFetchingCPDLogs, cpdLog } = useShallowEqualSelector((state) => state.history)

  useEffect(() => {
    getHistoryCPDLog()
  }, [])

  useDocumentTitle('CPD Log')

  const handleChangeDate = (value, field) => {
    getHistoryCPDLog({
      ...filterDate,
      [field]: value ? value.toISOString() : null,
    })

    setFilterDate({
      ...filterDate,
      [field]: value ? value.toISOString() : null,
    })
  }

  const handleFetchSelectedPDF = () => {
    getHistoryCPDLogPdf({ record_ids: selectedRowKeys })
  }

  const handleFetchAllPDF = () => {
    getHistoryCPDLogPdf({
      record_ids: cpdLog.map((log) => log.id),
    })
  }

  const onSelectChange = (value) => {
    setSelectedRowKeys(value)
  }

  const handleChangeComment = debounce((value, id) => {
    updateCommentCPDLog(id, { comment: value })
  }, 1000)

  const columns = [
    {
      title: 'Webinar title',
      dataIndex: 'title',
      key: 'title',
      render: (title, logRow) => {
        return (
          <Log.Title>
            {title}
            {logRow.is_manual && (
              <Log.ManualTag>
                <Log.Action /> <span>Manual entry</span>
              </Log.ManualTag>
            )}
          </Log.Title>
        )
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => {
        if (type === 'LIVE') {
          return <Tag color={theme.colors.red[200]}>{type}</Tag>
        }

        if (type === 'IN PERSON') {
          return <Tag color={theme.colors.lightBlue[200]}>{type}</Tag>
        }

        if (type === 'RECORDED') {
          return (
            <Tag color={theme.colors.yellow[200]} style={{ color: theme.colors.blue[200] }}>
              {type}
            </Tag>
          )
        }

        return null
      },
    },
    {
      title: 'Presenter/s',
      dataIndex: 'presenter',
      key: 'presenter',
      render: (presenter) => presenter.join(', '),
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      className: 'ant-table-cell-cpd-log-duration',
      render: (duration) => `${duration} mins`,
    },

    {
      title: 'Date',
      dataIndex: 'completion_time',
      key: 'completion_time',
      render: (completionTime) => date.formatUTC(completionTime, 'dd/MM/yyyy'),
    },
    {
      className: 'ant-table-cell-cpd-log',
      title: 'Further comments',
      dataIndex: 'comment',
      key: 'comment',
      render: (comment, { id }) => (
        <Input.TextArea
          placeholder='Start typing here...'
          autoSize={{ minRows: 2 }}
          style={{ minWidth: 200 }}
          onChange={(e) => handleChangeComment(e.target.value, id)}
          defaultValue={comment}
        />
      ),
    },
    {
      key: 'remove',
      className: 'ant-table-cell-cpd-log-icon',
      dataIndex: 'id',
      render: (id, row) => (
        <Log.Remove disabled={!row.is_manual} src={images.iconRemove} onClick={() => removeCPDLog(id)} />
      ),
      align: 'right',
    },
  ]

  return (
    <Wrapper>
      <ModalButton>
        <ModalButton.Icon>
          <ModalButton.Icon.Inner onClick={() => setShowVideoModal(true)} />
        </ModalButton.Icon>
        <ModalButton.Info>
          <ModalButton.Info.Title>Play video</ModalButton.Info.Title>
          <ModalButton.Info.Description>How to use the CPD Log</ModalButton.Info.Description>
        </ModalButton.Info>
      </ModalButton>
      <Log>
        <Title level={3}>CPD Log</Title>

        <VideoModal visible={showVideoModal} onCancel={() => setShowVideoModal(false)} />
        <ModalAddTraining visible={modalAddVisible} onCancel={() => setModalAddVisible(false)} />

        <Log.Filter>
          <Log.Filter.Label>Filter by date:</Log.Filter.Label>

          <Log.Filter.Inner>
            <DatePicker placeholder='From' onChange={(value) => handleChangeDate(value, 'start_date')} />
            <Log.Filter.Divider>-</Log.Filter.Divider>
            <DatePicker placeholder='To' onChange={(value) => handleChangeDate(value, 'end_date')} />
          </Log.Filter.Inner>
        </Log.Filter>

        <Log.Selected>
          <Text>
            Selected rows: <Text strong>{selectedRowKeys.length}</Text>
          </Text>

          <Log.Selected.Inner>
            <Button type='primary' onClick={handleFetchSelectedPDF} disabled={!selectedRowKeys?.length} ghost>
              Download selected
            </Button>
            <Button type='primary' onClick={handleFetchAllPDF} disabled={cpdLog.length === 0}>
              Download all
            </Button>
          </Log.Selected.Inner>
        </Log.Selected>

        <Table
          className='table-cpd-log'
          rowKey={(row) => row.id}
          rowSelection={{ onChange: onSelectChange, selectedRowKeys }}
          loading={isFetchingCPDLogs}
          dataSource={cpdLog}
          columns={columns}
          pagination={false}
          scroll={{ x: '90rem' }}
        />

        <Log.Create onClick={() => setModalAddVisible(true)}>
          Add custom Training Event <PlusSquareOutlined />
        </Log.Create>
      </Log>
    </Wrapper>
  )
}
export default AccountLog
