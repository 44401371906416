import React, { useEffect } from 'react'
import { Grid } from 'antd'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import { images } from '../../../assets'
import { useShallowEqualSelector } from '../../../hooks'
import { theme } from '../../../styles'
import { Ellipse } from '../../components'
import { Layout, FixedBar, Wrapper, Logotype } from './styled'

const { useBreakpoint } = Grid

const AuthLayout = () => {
  const navigate = useNavigate()
  const screens = useBreakpoint()

  const { isLogged } = useShallowEqualSelector((state) => state.auth)

  useEffect(() => {
    if (isLogged) {
      navigate('/', { replace: true })
    }
  }, [isLogged])

  return (
    <Layout>
      <Layout.Content>
        <FixedBar>
          <Link to='/'>
            <Logotype src={images.Logotype} />
          </Link>

          <FixedBar.Circle>
            <Ellipse type='circle' color={theme.colors.green[200]} size={screens.lg ? 20 : 5.6} />
          </FixedBar.Circle>

          <FixedBar.SemiCircle>
            <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={8} rotate={-45} />
          </FixedBar.SemiCircle>

          <FixedBar.SemiRing>
            <Ellipse type='semiring' color={theme.colors.yellow[200]} size={screens.lg ? 28 : 8} rotate={-45} />
          </FixedBar.SemiRing>
        </FixedBar>

        <Wrapper>
          <Outlet />
        </Wrapper>
      </Layout.Content>
    </Layout>
  )
}

export default AuthLayout
