import React from 'react'
import { Avatar, Badge, Button, Tag, Tooltip, Typography, Grid } from 'antd'
import { useNavigate } from 'react-router-dom'
import { images } from '../../../assets'
import { date } from '../../../helpers'
import { theme } from '../../../styles'
import { Item } from './styled'

const { Title, Text } = Typography
const { useBreakpoint } = Grid

const getCategoriesDetails = (item) => {
  const categories = item.categories.map((category) => category.title)

  return {
    first: categories[0],
    more: categories.length > 1 ? categories.splice(1, categories.length) : null,
  }
}

const WebinarPreviousItem = ({ item }) => {
  const navigate = useNavigate()
  const screens = useBreakpoint()

  const categories = getCategoriesDetails(item)
  const ellipsisRows = item.completion > 0 ? 3 : 4

  return (
    <Item>
      <Item.Inner variant={item.speakers?.some((speaker) => speaker.id === 10) ? 'gill' : 'default'}>
        <Item.Header>
          <Avatar.Group
            maxCount={3}
            size={52}
            maxStyle={{
              backgroundColor: theme.colors.lightBlue[200],
              fontSize: '1.4rem',
              fontWeight: 600,
            }}
          >
            {item.speakers.map((speaker) => (
              <Avatar key={speaker.image} src={speaker.image} />
            ))}
          </Avatar.Group>

          {item.speakers.length > 0 && (
            <Item.Header.Name>
              <Text strong>
                {item.speakers.length > 1 ? (
                  'Multiple Speakers'
                ) : (
                  <Item.Header.Link to={`/speakers/${item.speakers[0].slug}`}>{item.speakers[0].name}</Item.Header.Link>
                )}
              </Text>
              <Item.Header.Company>{item.speakers[0].company}</Item.Header.Company>
            </Item.Header.Name>
          )}
        </Item.Header>

        <Item.TitleWrapper>
          {+item.completion >= 99 && (
            <Item.Tag>
              <Tag color={theme.colors.green[200]}>
                <Item.Tag.Icon src={images.iconTagWatched} /> Watched
              </Tag>
            </Item.Tag>
          )}

          {+item.completion !== 0 && +item.completion < 99 && (
            <Item.Tag>
              <Tag color={theme.colors.lightBlue[200]}>
                <Item.Tag.Icon src={images.iconTagContinueWatching} /> Continue Watching
              </Tag>
            </Item.Tag>
          )}

          <Title
            level={4}
            ellipsis={{ rows: ellipsisRows, expandable: true, symbol: ' ' }}
            onClick={() => navigate(`/previous-webinars/${item.slug}`)}
          >
            {item.title}
          </Title>
        </Item.TitleWrapper>

        <Item.Info>
          <Item.Details>
            <Item.Details.Name>
              <Item.Details.Icon src={images.iconDate} />
              <Item.Details.Label>Webinar date</Item.Details.Label>
            </Item.Details.Name>

            <Item.Details.Value>{date.formatUTC(item.start_time)}</Item.Details.Value>
          </Item.Details>

          <Item.Details>
            <Item.Details.Name>
              <Item.Details.Icon src={images.iconRegion} />
              <Item.Details.Label>Region</Item.Details.Label>
            </Item.Details.Name>

            <Item.Details.Value>
              <Item.Details.Truncate>{item.region.title}</Item.Details.Truncate>
            </Item.Details.Value>
          </Item.Details>

          <Item.Details>
            <Item.Details.Name>
              <Item.Details.Icon src={images.iconCategory} />
              <Item.Details.Label>Category</Item.Details.Label>
            </Item.Details.Name>

            <Item.Details.Value>
              {screens.xs && (
                <Tooltip title={categories.first}>
                  <Item.Details.Truncate>{categories.first}</Item.Details.Truncate>
                </Tooltip>
              )}
              {!screens.xs && (
                <>
                  <Item.Details.Truncate>{categories.first}</Item.Details.Truncate>

                  {categories.more && (
                    // eslint-disable-next-line unicorn/prefer-spread
                    <Tooltip title={categories.more.concat(categories.first).join(', ')}>
                      <Badge count={`+${categories.more.length}`} title='' />
                    </Tooltip>
                  )}
                </>
              )}
            </Item.Details.Value>
          </Item.Details>
        </Item.Info>

        <Item.Watch>
          {+item.completion >= 99 && (
            <Button type='primary' onClick={() => navigate(`/previous-webinars/${item.slug}`)} ghost block>
              Watch again <Item.Watch.Icon src={images.iconPlay} />
            </Button>
          )}

          {+item.completion !== 0 && +item.completion < 99 && (
            <Button type='primary' onClick={() => navigate(`/previous-webinars/${item.slug}`)} ghost block>
              Resume <Item.Watch.Icon src={images.iconPlay} />
            </Button>
          )}

          {+item.completion === 0 && (
            <Button type='primary' onClick={() => navigate(`/previous-webinars/${item.slug}`)} ghost block>
              Watch now <Item.Watch.Icon src={images.iconPlay} />
            </Button>
          )}
        </Item.Watch>
      </Item.Inner>
    </Item>
  )
}

export default WebinarPreviousItem
