import React, { useCallback } from 'react'
import { useActions } from '../../../hooks'
import { auth as authActions } from '../../../store/actions'
import LoginPage from './LoginPage'

const LoginPageContainer = () => {
  const [authDispatch] = useActions([authActions])
  const authLogin = useCallback(authDispatch.authLogin, [authDispatch])
  const resetFailedLogin = useCallback(authDispatch.resetFailedLogin, [authDispatch])

  const props = {
    authLogin,
    resetFailedLogin,
  }

  return <LoginPage {...props} />
}

export default LoginPageContainer
