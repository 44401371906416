import React, { useCallback } from 'react'
import { useActions } from '../../../hooks'
import { auth as authActions, speakers as speakersActions } from '../../../store/actions'
import AccountPage from './AccountPage'

const AccountPageContainer = () => {
  const [authDispatch] = useActions([authActions])
  const authLogout = useCallback(authDispatch.authLogout, [authDispatch])
  const getProfileDetails = useCallback(authDispatch.getProfileDetails, [authDispatch])

  const [speakersDispatch] = useActions([speakersActions])
  const getSpeakersList = useCallback(speakersDispatch.getSpeakersList, [speakersDispatch])

  const props = {
    authLogout,
    getProfileDetails,
    getSpeakersList,
  }

  return <AccountPage {...props} />
}

export default AccountPageContainer
