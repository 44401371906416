import React from 'react'
import { Typography, Button, Avatar, Progress } from 'antd'
import { useNavigate } from 'react-router-dom'
import { images } from '../../../assets'
import { theme } from '../../../styles'
import { Item } from './styled'

const { Title, Text } = Typography

const WebinarsContinueWatchingItem = ({ item }) => {
  const navigate = useNavigate()

  return (
    <Item>
      <Item.Inner>
        <Item.Header>
          <Item.Header.Inner>
            <Avatar.Group
              maxCount={3}
              size={52}
              maxStyle={{
                backgroundColor: theme.colors.lightBlue[200],
                fontSize: '1.4rem',
                fontWeight: 600,
              }}
            >
              {item.speakers.map((speaker) => (
                <Avatar key={speaker.image} src={speaker.image} />
              ))}
            </Avatar.Group>

            {item.speakers.length > 0 && (
              <Item.Header.Name>
                <Text strong>{item.speakers.length > 1 ? 'Multiple Speakers' : `${item.speakers[0].name}`}</Text>
                <Item.Header.Company>{item.speakers[0].company}</Item.Header.Company>
              </Item.Header.Name>
            )}
          </Item.Header.Inner>

          <Item.Progress>
            <Progress
              type='circle'
              percent={Math.round(item.completion)}
              width={48}
              strokeColor={theme.colors.green[200]}
              strokeWidth={8}
              trailColor='transparent'
            />
          </Item.Progress>
        </Item.Header>

        <Title level={4} ellipsis={{ rows: 4 }}>
          {item.title}
        </Title>

        <Item.Watch>
          <Button type='primary' onClick={() => navigate(`/previous-webinars/${item.slug}`)} ghost block>
            Continue watching <Item.Watch.Icon src={images.iconPlay} />
          </Button>
        </Item.Watch>
      </Item.Inner>
    </Item>
  )
}

export default WebinarsContinueWatchingItem
