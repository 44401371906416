import React, { useEffect } from 'react'
import { Grid, Row, Col, Typography } from 'antd'
import queryString from 'query-string'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'
import { theme } from '../../../styles'
import { LayoutCentering, Ellipse, FilterBy, WebinarsPreviousList, PaginationDots } from '../../components'
import GillHeader from './components/GillHeader'
import { Webinars } from './styled'

const { useBreakpoint } = Grid

const HARDCODED_QUERY = { speakers: [10] }

const GillSteelPage = ({ getWebinarsRecorded }) => {
  const screens = useBreakpoint()

  const { isFetchingRecordedWebinars, recordedWebinars } = useShallowEqualSelector((state) => state.webinars)

  useEffect(() => {
    const parseParams = queryString.parse(window.location.search, { arrayFormat: 'bracket' })

    getWebinarsRecorded({ ...parseParams, ...HARDCODED_QUERY })
  }, [])

  useDocumentTitle('Gill Steel (Lawskills)')

  return (
    <Webinars>
      <Webinars.CircleGreen>
        <Ellipse type='circle' color={theme.colors.green[200]} size={15.3} />
      </Webinars.CircleGreen>

      <Webinars.SemiRing>
        <Ellipse type='semiring' color={theme.colors.yellow[200]} size={25} rotate={-45} />
      </Webinars.SemiRing>

      <Webinars.SemiCircle>
        <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={13.3} rotate={-45} />
      </Webinars.SemiCircle>

      <GillHeader title='Previous Webinars' />

      <LayoutCentering>
        <Row gutter={[32, 32]} wrap={!screens.xl}>
          <Col {...(screens.xl ? { flex: '292px' } : { xs: 24 })}>
            <FilterBy
              onRequest={getWebinarsRecorded}
              visibleCategories
              context='webinars'
              customParams={HARDCODED_QUERY}
            />
          </Col>

          <Col {...(screens.xl ? { flex: 'auto' } : { xs: 24 })}>
            <Typography style={{ padding: '0 15px', fontSize: 14, marginBottom: 40 }}>
              <Typography.Paragraph strong style={{ fontSize: 16 }}>
                In addition to our Expert Webinars, Estate Research are delighted to offer more than 30 Webinars from
                the LawSkills platform, presented by the award-winning Gill Steel.
              </Typography.Paragraph>

              <Typography.Paragraph>
                All of the webinars are designed to provide practitioners with an overview of Private Client
                developments in English and Welsh law from the last three years. Topics include Tax and Trusts including
                Trust Registration, Estate Administration, Will Drafting, Mental Capacity, Deputyships and Elderly
                Client. The one-hour sessions allow users to achieve their CPD and ensure continuing competence, all in
                manageable chunks of time.
              </Typography.Paragraph>

              <Typography.Paragraph>
                Scroll through the webinars below or filter through the options via topic using the category filters to
                the left.
              </Typography.Paragraph>
            </Typography>

            <Webinars.List>
              <WebinarsPreviousList loading={isFetchingRecordedWebinars} dataSource={recordedWebinars.list} />
              <PaginationDots
                onRequest={getWebinarsRecorded}
                meta={recordedWebinars.meta}
                customParams={HARDCODED_QUERY}
                isScrollToTop
              />
            </Webinars.List>
          </Col>
        </Row>
      </LayoutCentering>
    </Webinars>
  )
}

export default GillSteelPage
