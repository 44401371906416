import React, { useCallback } from 'react'
import { useActions } from '../../../hooks'
import { forms as formsActions, panels as panelsActions } from '../../../store/actions'
import ModalReferACase from './ModalReferACase'

const ModalReferACaseContainer = () => {
  const [formDispatch] = useActions([formsActions])
  const submitForm = useCallback(formDispatch.referACase, [formDispatch])
  const [panelsDispatch] = useActions([panelsActions])
  const getPanelsSettings = useCallback(panelsDispatch.getPanelsSettings, [panelsDispatch])

  const props = {
    submitForm,
    getPanelsSettings,
  }

  return <ModalReferACase {...props} />
}

export default ModalReferACaseContainer
