import React, { useCallback } from 'react'
import { useActions } from '../../../hooks'
import { webinars as webinarsActions, regions as regionsActions } from '../../../store/actions'
import UpcomingWebinarsPage from './UpcomingWebinarsPage'

const UpcomingWebinarsPageContainer = () => {
  const [webinarsDispatch] = useActions([webinarsActions])
  const getWebinarsUpcoming = useCallback(webinarsDispatch.getWebinarsUpcoming, [webinarsDispatch])
  const registerToAllWebinars = useCallback(webinarsDispatch.registerToAllWebinars, [webinarsDispatch])
  const cancelWebinarRegistration = useCallback(webinarsDispatch.cancelWebinarRegistration, [webinarsDispatch])

  const [regionsDispatch] = useActions([regionsActions])
  const getRegionsList = useCallback(regionsDispatch.getRegionsList, [regionsDispatch])

  const props = {
    getWebinarsUpcoming,
    getRegionsList,
    registerToAllWebinars,
    cancelWebinarRegistration,
  }

  return <UpcomingWebinarsPage {...props} />
}

export default UpcomingWebinarsPageContainer
