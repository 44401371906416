import React, { useCallback } from 'react'
import { useActions } from '../../../hooks'
import { blogPosts as blogPostsActions } from '../../../store/actions'
import BlogPostDetailsPage from './BlogPostDetailsPage'

const BlogPostDetailsPageContainer = () => {
  const [blogPostsDispatch] = useActions([blogPostsActions])
  const getBlogPostDetails = useCallback(blogPostsDispatch.getBlogPostDetails, [blogPostsDispatch])

  const props = {
    getBlogPostDetails,
  }

  return <BlogPostDetailsPage {...props} />
}

export default BlogPostDetailsPageContainer
