import React, { useEffect } from 'react'
import { Typography, Button, Grid } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useShallowEqualSelector } from '../../../../../hooks'
import { LayoutCentering, CustomCarousel, WebinarFeaturedItem } from '../../../../components'
import { FeaturedWebinars } from './styled'

const { Title } = Typography
const { useBreakpoint } = Grid

const MainFeaturedWebinars = ({ getWebinarsRecorded, title = 'Featured Webinars' }) => {
  const navigate = useNavigate()
  const screens = useBreakpoint()

  const { recordedWebinars } = useShallowEqualSelector((state) => state.webinars)

  useEffect(() => {
    getWebinarsRecorded({ featured: true, per_page: 6 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FeaturedWebinars>
      <LayoutCentering>
        <FeaturedWebinars.Header>
          <Title level={2}>{title}</Title>

          {screens.lg && (
            <Button type='primary' onClick={() => navigate('/previous-webinars')}>
              View all webinars
            </Button>
          )}
        </FeaturedWebinars.Header>
      </LayoutCentering>

      <FeaturedWebinars.List>
        <CustomCarousel slidesToShow={screens.lg ? 1.04 : 1} variableWidth={screens.lg} infinite lightControls>
          {recordedWebinars.list.map((item) => (
            <FeaturedWebinars.Item key={item.id}>
              <WebinarFeaturedItem item={item} />
            </FeaturedWebinars.Item>
          ))}
        </CustomCarousel>
      </FeaturedWebinars.List>

      {!screens.lg && (
        <FeaturedWebinars.More>
          <Button type='primary' onClick={() => navigate('/previous-webinars')} block>
            View all webinars
          </Button>
        </FeaturedWebinars.More>
      )}
    </FeaturedWebinars>
  )
}

export default MainFeaturedWebinars
