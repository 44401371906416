import React, { useCallback } from 'react'
import { useActions } from '../../../../../hooks'
import { speakers as speakersActions } from '../../../../../store/actions'
import MainFeaturedSpeakers from './MainFeaturedSpeakers'

const MainFeaturedSpeakersContainer = () => {
  const [speakersDispatch] = useActions([speakersActions])
  const getSpeakersList = useCallback(speakersDispatch.getSpeakersList, [speakersDispatch])

  const props = {
    getSpeakersList,
  }

  return <MainFeaturedSpeakers {...props} />
}

export default MainFeaturedSpeakersContainer
