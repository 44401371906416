import React, { useEffect } from 'react'
import { Row, Col, Typography, Form, Input, Button } from 'antd'
import { images } from '../../../assets'
import { useDocumentTitle, useShallowEqualSelector } from '../../../hooks'
import { theme } from '../../../styles'
import { LayoutCentering, Ellipse } from '../../components'
import { Feedback } from './styled'

const { Title, Paragraph } = Typography

const FeedbackPage = ({ sendFeedback }) => {
  const [form] = Form.useForm()

  const { isLogged, profileDetails } = useShallowEqualSelector((state) => state.auth)
  const { isFetchingSendFeedback } = useShallowEqualSelector((state) => state.feedback)

  useEffect(() => {
    if (isLogged && profileDetails.id) {
      form.setFieldsValue({
        ...profileDetails,
        name: `${profileDetails.first_name} ${profileDetails.last_name}`,
      })
    }
  }, [isLogged, profileDetails.id])

  useDocumentTitle('Feedback')

  const onFinish = (values) => sendFeedback(values, form)

  return (
    <Feedback>
      <LayoutCentering>
        <Row gutter={[140, 32]}>
          <Col xl={13} xs={24}>
            <Feedback.Header>
              <Feedback.Icon src={images.iconFeedbackLikeLight} />

              <Title level={3}>
                Feedback for<span>Estate Research Learning Hub</span>
              </Title>
            </Feedback.Header>

            <Feedback.Details>
              We hope the Estate Research Learning Hub proves a useful resource for Private Client Practitioners and
              would love to hear your ideas of how we can continuously improve.
            </Feedback.Details>
            <Feedback.Text>
              If you have any topics that you would like us to try and cover, know of any speakers or article writers
              who would like to contribute or if you have any suggestions of how we can improve the service – please do
              let us know!
            </Feedback.Text>

            <Feedback.Refer>
              <Feedback.Refer.Inner>
                <Feedback.Refer.Title>Estate Research</Feedback.Refer.Title>

                <Paragraph>
                  A firm of International Probate Genealogists locating missing beneficiaries worldwide & providing
                  estate administration services to both the Private and Public sector.
                </Paragraph>

                <Feedback.Refer.Action>
                  <a
                    href='https://www.estateresearch.co.uk/private-client/refer-a-case'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <Button type='primary' ghost>
                      Refer a Case
                    </Button>
                  </a>
                </Feedback.Refer.Action>
              </Feedback.Refer.Inner>
            </Feedback.Refer>
          </Col>

          <Col xl={11} xs={24}>
            <Feedback.Card>
              <Feedback.Card.Pacman>
                <Ellipse type='pacman' color={theme.colors.yellow[200]} size={6} />
              </Feedback.Card.Pacman>

              <Feedback.Card.SemiCircle>
                <Ellipse type='semicircle' color={theme.colors.lightBlue[200]} size={6} rotate={-45} />
              </Feedback.Card.SemiCircle>

              <Feedback.Card.SemiRing>
                <Ellipse type='semiring' color={theme.colors.violet[200]} size={8.8} rotate={-45} />
              </Feedback.Card.SemiRing>

              <Feedback.Card.Title>Contact form</Feedback.Card.Title>

              <Form form={form} onFinish={onFinish} autoComplete='off' layout='vertical'>
                <Form.Item
                  label='Name'
                  name='name'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your name',
                    },
                  ]}
                >
                  <Input placeholder='Name' />
                </Form.Item>

                <Form.Item
                  label='Company'
                  name='company'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter company name',
                    },
                  ]}
                >
                  <Input placeholder='Company name' />
                </Form.Item>

                <Form.Item
                  label='Email address'
                  name='email'
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter your email',
                    },
                  ]}
                >
                  <Input placeholder='Your email address' />
                </Form.Item>

                <Form.Item
                  label='Your feedback'
                  name='feedback'
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your feedback',
                    },
                  ]}
                >
                  <Input.TextArea placeholder='Start typing here...' rows={4} />
                </Form.Item>

                <Feedback.Submit>
                  <Form.Item noStyle>
                    <Button type='primary' htmlType='submit' loading={isFetchingSendFeedback} block>
                      Submit
                    </Button>
                  </Form.Item>
                </Feedback.Submit>
              </Form>
            </Feedback.Card>
          </Col>
        </Row>
      </LayoutCentering>
    </Feedback>
  )
}

export default FeedbackPage
