import React, { useCallback } from 'react'
import { useActions } from '../../../hooks'
import { auth as authActions } from '../../../store/actions'
import RegisterPage from './RegisterPage'

const RegisterPageContainer = () => {
  const [authDispatch] = useActions([authActions])
  const authRegister = useCallback(authDispatch.authRegister, [authDispatch])
  const resetAuth = useCallback(authDispatch.resetAuth, [authDispatch])

  const props = {
    authRegister,
    resetAuth,
  }

  return <RegisterPage {...props} />
}

export default RegisterPageContainer
